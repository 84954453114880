.app-header {
    margin: 1em 0;
}

.container-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    background: rgba(255, 255, 255, 0.9);
}

.consejos-escritos {
    cursor: pointer;
    background-color: black;
    text-transform: uppercase;
    border: transparent solid 2px;
    margin-bottom: 1.2em;
    color: white;
    &:hover {
        background-color: white;
        color: black;
        border: $red solid 2px;
    }
}

.node-tree {
    cursor: pointer;
    color: black;
}

.node-tree:hover {
    background-color: black !important;
}

.sentence {
    display: inline-block;
    text-decoration: none;
    font-size: $node-font-size;
}

.navbar {
    min-height: 60px;
    width: 100%;
}

.header-login {
    display: flex;
    align-items: center;
    justify-content: center;
    // min-height: 52px;
    width: 100%;
    margin-left: 45px;
}

#logo-login {
    max-width: 28rem;
}

#logo-home {
    max-width: 22rem;
    @include media-breakpoint-up(xl) {
        max-width: 28rem;
    }
}

#header-sidebar-area,
.header-icons {
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
        font-size: 2rem;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        cursor: pointer;
    }
}

// TODO: Add custom xs breakpoint to theming
@media (max-width: 575px) {
    .header-title {
        width: 100%;
        align-items: center;
        margin-left: 45px;
        margin-bottom: 4px;
    }
    #logo-home {
        max-width: 13rem;
    }
    #logo-login {
        max-width: 18rem;
    }
}

@media (min-width: 576px) {
    #logoUD {
        position: absolute;
        left: 65px;
        right: 0;
        top: 0;
        bottom:0;
        margin: auto;
    }
}

#logoUD {
    max-width: 12rem;
    @include media-breakpoint-up(md) {
        max-width: 18rem;
    }
}

.alternate-selector {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;

    .form-group {
        margin: 0;
    }

    input[type="checkbox"] {
        height: 20px;
        width: 20px;
        margin-right: 10px;
    }
}

.react-player {
    height: 1px !important;
    width: 1px !important;
}

.speakers {
    margin-top: 2em;
    display: flex;
    justify-content: space-around;
    color: #fff;
    width: clamp(19rem, 48vw, 50rem);
}

.speaker-text{
    align-self: center;
}

.button-speaker {
    text-align: center;
    background-color: lightgray;
    padding: .3rem 1.5rem;
    cursor: pointer;
    min-width: 45%;

    span {
        font-size: .8rem;
        @include media-breakpoint-up(md) {
            font-size: 1rem;
        }
        text-transform: uppercase;
    }
}

.current-speaker {
    background: $red;
    font-weight: bold;
}

.card-mini {
    @include media-breakpoint-up(md) {
        width: 500px;
    }
}

.card-standard {
    width: 100%;
    margin-bottom: 3em;
}

.card-standard.transparent {
    background: none !important;
    border: none;

    .node-tree {
        background: $red;
        color: white;
        margin-bottom: .8em;
        text-align: left;
    }

    .quickmenu {
        background-color: black;
        color: white;
        margin-bottom: .8em !important;
        cursor: pointer;
        &:hover {
            background-color: $red;
        }
    }

    .card-title {
        background:rgba(255, 255, 255, 0.9);
        padding: 1em;
        text-transform: uppercase;
    }

    .card-text {
        padding-top: 1em;
        padding-bottom: 1em;
        background:rgba(255, 255, 255, 0.9);
        .node-tree {
            text-transform: none;
            background: none;
            .col {
                .row:last-child {
                    font-weight: normal;
                }
            }
        }
    }
}

.hide {
    display: none !important;
}

.logout-icon-container {
    display: flex;
    align-items: center;
    padding: 0.15em .1em;
    background-color: $red;
    color: #fff;
    margin: 2px 8px;
}

.list-icon {
    position: absolute;
    font-size: 22px;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto 0;
}

.top-icon {
    color: black;
    font-size: 2rem;
    margin: 2px 8px;
    &:hover {
        cursor: pointer;
    }
}

.top-icon.right {
    color: $red;
}

.inline-icon {
    margin-right: 8px;
    width: 24px;
}

.dropdown-toggle{
    &:after {
        color: black;
    }
}

.dropdown-menu {
    position: absolute !important;
  }

.link-no-decoration {
    color: rgba(white, .9);
    &:hover {
        color: rgba(white, .9);
    }
}

.react-transform-component {
    width: auto !important;
    justify-content: center;
}

.pdf-modal {
    min-width: fit-content;
    z-index: 1;
}

.qr-modal {
    z-index: 2;
    width: fit-content;
    margin: auto;
}

.pages-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 15px;
    background-color: rgba(black, .45);
}

.pdf-modal-body {
    padding: 0;

}

.pdf-options-body {
    padding: 0;
    text-align: center;
    background-color: rgb(231, 231, 231);
}

.pdf-modal-header {
    padding: 4px 0;
    background-color: rgba(black, .8);
    color: rgba(white, .9);
}

.pdf-modal-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 4px 0;
}

.pdf-modal-title {
    font-size: clamp(.5rem, 1.25vw, 1rem);
    margin-left: 4px;
    @include media-breakpoint-down(xs) {
        max-width: 200px;
    }
}

.pdf-icon {
    font-size: 28px;
    margin-right: 10px;
    cursor: pointer;
}

.modal-header .close {
    padding: 0;
    color:rgba(255, 255, 255, 0.9);
    margin: 0 5px;
    font-size: 38px;
}

.modal-footer {
    padding: 0;
}

.modal-footer > * {
    margin: 0;
}

.pdf-footer-container {
    display: flex;
    justify-content: space-around;
    font-size: clamp(.5rem, 1.25vw, 1rem);
    background-color: rgba(black, .8);
    color: rgba(white, .8);
    padding: 4px 0;
}

.pdf-pagination-form-container {
    display: inherit;
    align-items: center;
}

.pdf-pagination-form {
    padding: 0 4px;
}

.pdf-pagination-input {
    max-width: 2rem;
    text-align: right;
    background-color: rgba(black, .1);
    color: rgba(white, .8);
}

.pdf-pagination-arrows {
    display: inherit;
    align-items: center;
}

.my-buttons {
    background-color: $red;
    color: white;
    min-width: 150px;
    border-radius: 0px;
    font-size: $node-font-size;
    &:hover {
        background-color: black;
        color: white;
    }
    @include media-breakpoint-up(md) {
        font-size: .7rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: .875rem;
    }
    @include media-breakpoint-up(xl) {
        font-size: $button-font-size;
    }
}

.landing-img-container {
    display: flex;
    justify-content: space-between; 
    margin: 50px 0;
}

.landing-img {
    width: 95%;
    height: auto;
}

.body-text {
    font-size: $body-font-size;
    @include media-breakpoint-up(md) {
        max-width: 75%;
    }
    @include media-breakpoint-up(xl) {
        font-size: $body-font-size-lg;
    }
}

.semi-bold {
    font-weight: 600;
}

.subtitle-text {
    font-size: $subtitle-font-size-lg;
    @include media-breakpoint-up(md) {
        font-size: $subtitle-font-size;
    }
    @include media-breakpoint-up(lg) {
        font-size: $subtitle-font-size-lg;
    }
}

.button-text {
    font-size: .875rem;
    @include media-breakpoint-up(lg) {
        font-size: 1rem;
    }
    @include media-breakpoint-up(xl) {
        font-size: $body-font-size-lg;
    }
}

.language-selector-header {
    background-color: $red;
    color: white;
    text-align: center;
}

.logout-button-with-text {
    display: flex;
    align-items: center;
    padding: 0.3rem 1rem;
    background-color: $red;
    color: white;
    cursor: pointer;
    font-size: $body-font-size;
    &:hover {
        background-color: black;
    }
    @include media-breakpoint-up(575px) {
        font-size: .875rem;
    }
    @include media-breakpoint-up(xl) {
        font-size: $body-font-size-lg;
    }
}

.logout-icon {
    max-width: 22px;
    max-height: 22px;
    @include media-breakpoint-up(sm) {
        max-width: 28px;
        max-height: 28px;
    }
}

.login-labels {
    font-size: $button-font-size;
}

.node-row {
    justify-content: space-around;
    width: 100%;
    flex-wrap: nowrap;
}

.top-icon-img {
    max-width: 28px;
    max-height: 28px;
    cursor: pointer;
}


.search-input {
    border-radius: 0;
}

.search-page-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
        width: 70%;
    }
}

h1 {
    font-size: 12.5vw;
    font-weight: bold;
    @include media-breakpoint-up(sm) {
        font-size: 4.5rem;
    }
    @include media-breakpoint-up(md) {
        font-size: 2.5rem;
    }
    @include media-breakpoint-up(lg) {
        font-size: 3.4rem;
    }
    @include media-breakpoint-up(xl) {
        font-size: 4.1rem;
    }
}

.search-bar {
    margin-top: 20px;
    width: clamp(19rem, 46vw, 48rem);
}

.card-container {
    width: 100%;
    @include media-breakpoint-up(sm) {
        width: 70%;
    }
}

.form-control:focus {
    outline: 0 !important;
    border-color: transparent;
    box-shadow: 0 0 0 0;
}

.search-button {
    background-color: white;
    border: none;
    margin-top: 1px;
    margin-left: -1px;
}

.footer {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    background-color: #c7342d94;
    color: white;
    position: fixed;
    top: auto;
    bottom: 0;
    padding: .5rem;
    font-weight: 600;
    @include media-breakpoint-down(md) {
        font-size: 12px;
        justify-content: space-around;
    }
}

.footer-text {
    text-align: center;
}

.footer-section {
    cursor: pointer;
}

.cookie-consent-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
}

.cookie-consent-modal-content {
    background-color: rgb(231, 231, 231);
    padding: 20px;
    border-radius: 5px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }

.cookie-consent-buttons {
    background-color: $red;
    font-size: 12px;
    font-weight: bold;
    border: 0;
    min-width: 100px;
    &:hover {
        background-color: black;
        color: white;
    }
}

.cookie-consent-links {
    color: black;
    text-decoration: underline;
    font-weight: bold;
}

#cookie-item-container {
    border: 1px solid rgba(black, .45);
    min-height: 40px;
}

#cookie-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    cursor: pointer;
}

#cookie-item-title {
    display: inherit;
    align-items: inherit;
}

#cookie-item-text {
    font-size: 12px;
    font-weight: 700;
    color: #696969;
    margin-left: 4px;
}

#cookie-item-value {
    font-size: 10px;
    font-weight: 700;
    color:#3860be;
}

#cookie-item-hidden-text {
    font-size: 10px;
    background-color: #F8F8F8;
    padding: 8px;
}
