// Google Font
@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;900&display=swap');

// Override default variables before the import
$font-family-sans-serif: 'Montserrat', 'Fira Sans', sans-serif;
$red: #C7342D;
$body-bg: $red;
$body-font-size-lg: 1.33rem;
$body-font-size: 1rem;
$node-font-size: 0.875rem;
$button-font-size: 1.16rem;
$subtitle-font-size-lg: .92rem;
$subtitle-font-size: .62rem;


// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';