@import "styles/_theme.scss";
@import "styles/styles.scss";

* {
  box-sizing: border-box;
}

body {
  // background-image: url(./assets/images/background.jpeg);
  // background-attachment: fixed;
  background-color: rgb(231, 231, 231);
  // min-height: 100vh;
  display: flex;
  font-weight: 400;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

#lang-selector {
  font-size: 12px;
  span {
    margin: 0 3px;
    cursor: pointer;
    padding: 3px 5px;
  }
  span:hover {
    background: #f0f0f0;
  }
}

.auth-wrapper {
  margin-top: 5em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: $body-bg;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: $body-bg;
}
